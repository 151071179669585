import React from 'react';
import {
    Box,
} from '@chakra-ui/react';

import { Player } from '@lottiefiles/react-lottie-player';
import SnowscapesLottie from '../../lotties/snowscapes-no-lights-flashing.json'

export default function Snowscapes() {
    return (
        <Box>
            <Player
                autoplay
                loop
                src={SnowscapesLottie}
                style={{ width: '100%' }}
            >
            </Player>
        </Box>
    )
}